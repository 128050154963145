import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"
import { getCurrentLangKey } from 'ptz-i18n';

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from '../components/Superbites/HeaderSection/HeaderSection'
import LatestPodcasts from '../components/Superbites/LatestPodcasts/LatestPodcasts'
import AllPodcasts from '../components/Superbites/AllPodcasts/AllPodcasts'

const Superbites = props => {
    const url = props.location.pathname;
    const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
   
    let langKey = getCurrentLangKey(langs, defaultLangKey, url);
    let homeLink = `/${langKey}/`

    const seoData = get(props, 'data.pageData.nodes[0].seo',[])
    const pageData = get(props, 'data.pageData.nodes[0]', [])
    const latestPodcasts = get(props, 'data.latestPodcasts.nodes', [])
    const podcastData = get(props, 'data.allPodcasts', [])

    let data = {
        title: seoData.title,
        description: seoData.description,
        lang: 'en',
        image: seoData.social.facebook.image && seoData.social.facebook.image.url,
        imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
        robots: seoData.advanced.robots && seoData.advanced.robots
    }
  
    // slugs for translation
    const slugs = {
        slugNl: props.data.translatedUrl.nodes[0].slug,
        slugEn: props.data.pageData.nodes[0].slug,
    }
  
    return(
        <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
            <SEO {...data} />
            <HeaderSection url={homeLink} pageData={pageData}/>
            <LatestPodcasts latestData={latestPodcasts}/>
            <AllPodcasts allPodcasts={podcastData} url={homeLink}/>
        </Layout>
    )
}

export default Superbites

export const query = graphql`
  {
    site{
      siteMetadata{
        languages {
          defaultLangKey
          langs
        }
      }
    }
    pageData: allCraftSuperbitesSuperbitesEntry(filter: {siteId: {eq:1}}){
      nodes{
        slug
        language
        title
        pageIntro
        seo {
            title
            description
            social {
              facebook {
                image {
                  url
                }
              }
              twitter {
                image {
                  url
                }
              }
            }
            advanced {
              robots
            }
          }
      }
    }
    translatedUrl: allCraftSuperbitesSuperbitesEntry(filter: {siteId: {eq:1}}){
      nodes{
        slug
      }
    }
    latestPodcasts: allCraftSuperbiteDefaultEntry(sort: {order: DESC, fields: dateCreated}, limit: 2, filter: {siteId: {eq:1}}) {
        nodes{
          title
          superbitesIntroShort
          superbitesIntroLong
          superbitesTitle
          superbitesImage{
            url
          }
          superbitesEpisode
          superbitesLinkEpisode
          categoriesServices{
              title
          }
          categoriesServices {
              ... on craft_serviceCategory_Category {
                  title
              }
          }
        }
    }
    allPodcasts: allCraftSuperbiteDefaultEntry(sort: {order: DESC, fields: dateCreated}, filter: {siteId: {eq:1}}) {
        nodes{
            title
            superbitesIntroShort
            superbitesIntroLong
            superbitesTitle
            superbitesImage{
              url
            }
            superbitesEpisode
            superbitesLinkEpisode
            categoriesServices{
                title
            }
            categoriesServices {
                ... on craft_serviceCategory_Category {
                    title
                }
            }
        }
    }
  }
`